<template>
  <InfoCard>
    <div v-if="!profileData.educations.length"></div>
    <template v-else>
      <div
        v-for="(education, index) in profileData.educations"
        :key="index"
        class="pb-3 bb-1 mb-3"
      >
        <div class="grid grid__layout gap-8 mb-3 grid__mobile m-gap-3">
          <div class="col-4 m-col-12">
            <h2 class="text__grey fs-14 fw-400 mb-1">
              Name of Educational Institution/School
            </h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ education.institute }}
            </p>
          </div>
          <div class="col-3 m-col-12">
            <h2 class="text__grey fs-14 fw-400 mb-1">Minimum Qualification</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ education.degree.title }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Start date</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ $moment(education.startDate).format('MMM, YYYY') }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">End date</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{
                education.endDate
                  ? $moment(education.endDate).format('MMM, YYYY')
                  : 'On-going'
              }}
            </p>
          </div>
        </div>

        <div>
          <h2 class="text__grey fs-14 fw-400 mb-1">Course of Study</h2>
          <p class="lh-32 desc">
            {{ education.details || 'No details added yet.' }}
          </p>
        </div>
      </div>
    </template>

    <div class="flex flex__end">
      <Button
        @click="
          $router.push({
            name: 'update-profile',
            query: { tab: 'Educations' }
          })
        "
        class="edit__btn"
        theme="blue"
        >Edit Details</Button
      >
    </div>
  </InfoCard>
</template>

<script>
import InfoCard from '@/components/InfoCard';

export default {
  name: 'Educations',
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  components: {
    InfoCard
  }
};
</script>

<style scoped lang="scss">
@use '../../../assets/scss/mixins/index' as *;
@use '../index' as *;
</style>
