<template>
  <InfoCard>
    <h2 class="text__grey fs-14 fw-400 mb-1">
      Social Networks
    </h2>
    <p class="fs-16 text__dark-grey fw-600">
      <table class="social-network mt-2">
        <tr  v-for="(sn, i) in profileData.socialNetworks"
        :key="sn.site">
          <th>
            {{sn.site}}
          </th>
          <td>
            <a :href="sn.url" target="_blank">
              {{sn.url}}
            </a>
            
          </td>
        </tr>
      </table>  
    </p>

    <div class="flex flex__end">
      <Button
        @click="
          $router.push({
            name: 'update-profile',
            query: { tab: 'SocialNetworks' }
          })
        "
        class="edit__btn"
        theme="blue"
      >
        Edit Details
      </Button>
    </div>
  </InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import Button from "@/components/Button";

export default {
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  components: {
    InfoCard,
    Button
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@use "../../../assets/scss/mixins/index.scss" as *;
// @use "../../assets/scss/mixins/index.scss" as *;

.desc {
  width: 60%;
  color: #505565;

  @include query("mobile") {
    width: 100%;
  }
}

.edit__btn {
  width: 235px;

  @include query("mobile") {
    width: 100%;
  }
}

table.social-network th, td{
  text-align: left;
}table.social-network td{
  padding: 5px 11px;
}
</style>
