<template>
  <InfoCard>
    <h2 class="text__grey fs-14 fw-400 mb-1">Profile Summary</h2>
    <div class="bb-1 mb-4 pb-2">
      <p class="lh-32 fs-16 pb-3 desc">
        <span class="mb-1">{{ profileData.profile.profileSummary }}</span>
      </p>
    </div>
    <div class="mb-4">
      <!-- <p class="mb-5 lh-40">
        <span class="mr-5 text__grey fs-14 fw-400"> LinkedIn Profile </span>
        <a class="text-blue text-underline" href="#">
          {{ profile.linkedin_profile || "N/A" }}
        </a>
      </p> -->
      <!-- <p class="mb-5">
        <span class="mr-5 text__grey fs-14 fw-400">Github</span>
        <a class="text-blue text-underline" href="#">{{
          profile.github_url || "N/A"
        }}</a>
      </p>
      <p class="mb-5">
        <span class="mr-5 text__grey fs-14 fw-400"> Behance</span>
        <a class="text-blue text-underline" href="#">{{
          profile.behance_url || "N/A"
        }}</a>
      </p> -->
    </div>

    <div class="flex flex__end">
      <Button
        @click="
          $router.push({
            name: 'update-profile',
            query: { tab: 'MyProfile' }
          })
        "
        class="edit__btn"
        theme="blue"
        >Edit Details</Button
      >
    </div>
  </InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import Button from "@/components/Button";

export default {
  name: "ProfileSummary",
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  components: {
    InfoCard,
    Button
  },
  data() {
    return {
      profile: { ...this.profileData.profile }
    };
  }
};
</script>

<style scoped lang="scss">
@use "../index" as *;
</style>
