<template>
    <div class="cv__card flex flex__spaced flex__item-center mb-2">
        <div class="flex flex__item-center">
            <div class="mr-1">
                <img src="../../assets/icons/pdf-icon.svg" alt="pdf-icon" />
            </div>
            &nbsp;
            <div>
                <p class="fs-16 fw-600 text__dark-grey">
                    {{ CV.name }}
                </p>
                <p class="text__grey fs-14 fw-600">{{ toKB(CV.size) }} KB</p>
            </div>
        </div>
        <div class="flex">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "CVCard",
    props: {
        CV: {
            type: [Object, File],
            default: () => {},
        },
    },
    methods: {
        toKB(size) {
            return Math.round(size / 1000);
        },
    },
};
</script>

<style scoped lang="scss">
.cv {
    &__card {
        padding: 16px 20px;
        background: #fafafc;
        border-radius: 4px;
    }
}
</style>
