<template>
  <InfoCard>
    <div class="bb-1 mb-3">
      <h1 class="fs-24 m-fs-20 text-blue mb-3">CV</h1>
      <CVCard
        v-for="cv in documents"
        :key="cv.id"
        :CV="cv"
        class="cv__card mb-3"
      >
        <div class="flex">
          <!-- <button class="mr-2 p-0 cursor-pointer">
                          <img
                              src="../../../assets/icons/trash-icon.svg"
                              alt="trash-icon"
                          />
                      </button> -->
          <a :href="cv.url" class="p-0 cursor-pointer">
            <img
              src="../../../assets/icons/download-icon.svg"
              alt="download-icon"
            />
          </a>
        </div>
      </CVCard>
    </div>

    <!-- <div class="bb-1 mb-3">
              <h1 class="fs-24 m-fs-20 text-blue mb-3">Degree Certificate</h1>
              <CVCard :CV="degree" class="cv__card mb-3">
                  <div class="flex">
                      <button class="mr-2 p-0 cursor-pointer">
                          <img
                              src="../../../assets/icons/trash-icon.svg"
                              alt="trash-icon"
                          />
                      </button>
                      <button class="p-0 cursor-pointer">
                          <img
                              src="../../../assets/icons/download-icon.svg"
                              alt="download-icon"
                          />
                      </button>
                  </div>
              </CVCard>
          </div> -->

    <!-- <div class="bb-1 mb-3">
              <h1 class="fs-24 m-fs-20 text-blue mb-3">
                  NYSC Discharge Certificate
              </h1>
              <CVCard :CV="nyscCert" class="cv__card mb-3">
                  <div class="flex">
                      <button class="mr-2 p-0 cursor-pointer">
                          <img
                              src="../../../assets/icons/trash-icon.svg"
                              alt="trash-icon"
                          />
                      </button>
                      <button class="p-0 cursor-pointer">
                          <img
                              src="../../../assets/icons/download-icon.svg"
                              alt="download-icon"
                          />
                      </button>
                  </div>
              </CVCard>
          </div> -->

    <div class="flex flex__end">
      <Button
        @click="
          $router.push({
            name: 'update-profile',
            query: { tab: 'Resume' }
          })
        "
        class="edit__btn"
        theme="blue"
        >Edit Details</Button
      >
    </div>
  </InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import CVCard from "@/components/CVCard";
import seekerService from "../../../services/api/seekerService";

export default {
  name: "Resume",
  props: {
    profileData: {
      type: Object
    }
  },
  components: {
    InfoCard,
    CVCard
  },
  data() {
    return {
      loading: true,
      documents: []
    };
  },
  computed: {
    // documents() {
    //   return this.profileData.documents.map((document) => ({
    //     name: document.name,
    //     size: document.size
    //   }));
    // }
  },
  mounted() {
    this.loading = true;
    seekerService
      .getCvs()
      .then((res) => {
        console.log(res.data);
        this.documents = res.data.documents;
      })
      .catch((e) => {
        this.$handleError(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>

<style scoped lang="scss">
@use "../index" as *;
@use "../../../assets/scss/mixins/index" as *;

.cv__card {
  width: 442.5px;

  @include query("mobile") {
    width: 100%;
  }
}
</style>
