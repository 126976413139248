<template>
    <InfoCard>
        <div v-if="!projects.length" class="pb-2 bb-1 mb-2">
            <p>No projects to show</p>
        </div>
        <template v-else>
            <div class="pb-3 bb-1 mb-3">
                <div class="grid grid__layout gap-3 mb-3 grid__mobile">
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Project Title
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            ABC Private Limited
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Client Name
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            Charlie Bergson
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Project Status
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">Completed</p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">Duration</h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            1 Year 6 Months
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">Start Date</h2>
                        <p class="fs-16 text__dark-grey fw-600">Jun - 2019</p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Finished on
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">Dec - 2020</p>
                    </div>
                </div>

                <div>
                    <h2 class="text__grey fs-14 fw-400 mb-1">
                        Project Details
                    </h2>
                    <p class="lh-32 desc">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aenean euismod bibendum laoreet. Proin gravida dolor sit
                        amet lacus accumsan et viverra justo commodo. Proin
                        sodales pulvinar sic tempor. Sociis natoque penatibus et
                        magnis dis parturient.
                    </p>
                </div>
            </div>
            <div class="pb-3 mb-4">
                <div class="grid grid__layout grid__mobile gap-3 mb-3">
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Project Title
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            ABC Private Limited
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Client Name
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            Charlie Bergson
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Project Status
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">Completed</p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">Duration</h2>
                        <p class="fs-16 text__dark-grey fw-600">
                            1 Year 6 Months
                        </p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">Start Date</h2>
                        <p class="fs-16 text__dark-grey fw-600">Jun - 2019</p>
                    </div>
                    <div class="col-2 m-col-6">
                        <h2 class="text__grey fs-14 fw-400 mb-1">
                            Finished on
                        </h2>
                        <p class="fs-16 text__dark-grey fw-600">Dec - 2020</p>
                    </div>
                </div>

                <div>
                    <h2 class="text__grey fs-14 fw-400 mb-1">
                        Project Details
                    </h2>
                    <p class="lh-32 desc">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aenean euismod bibendum laoreet. Proin gravida dolor sit
                        amet lacus accumsan et viverra justo commodo. Proin
                        sodales pulvinar sic tempor. Sociis natoque penatibus et
                        magnis dis parturient.
                    </p>
                </div>
            </div>
        </template>
        <div class="flex flex__end">
            <Button
                @click="
                    $router.push({
                        name: 'update-profile',
                        query: { tab: 'Projects' },
                    })
                "
                class="edit__btn"
                theme="blue"
                >Edit Details</Button
            >
        </div>
    </InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard";

export default {
    name: "Projects",
    components: {
        InfoCard,
    },
    data() {
        return {
            projects: [],
        };
    },
};
</script>

<style scoped lang="scss">
@use "../../index" as *;
</style>
