<template>
  <InfoCard>
    <div class="pb-3 bb-1 mb-3">
      <h1 class="fs-24 m-fs-20 text-blue mb-3">Skills</h1>

      <div class="flex flex__item-center key__skills">
        <div class="skills__title">
          <p class="text__grey mr-2 fs-14">Key Skills</p>
        </div>
        <div class="flex__grow flex flex__wrap flex__shrink-1 flex__row-gap-2">
          <span
            v-for="skill in profileData.profile.skills"
            :key="skill"
            class="skills__tab skills__tab--round text-capitalize"
          >
            {{ skill }}
          </span>
        </div>
      </div>
    </div>

    <h1 class="fs-24 m-fs-20 text-blue mb-3">Experience</h1>

    <div v-if="!profileData.experiences.length">
      <p>No experiences added yet</p>
    </div>
    <template v-else>
      <div
        v-for="(experience, index) in profileData.experiences"
        :key="index"
        class="pb-3 bb-1 mb-3"
      >
        <div class="grid grid__layout gap-3 mb-3 grid__mobile">
          <div class="col-2 m-col-12">
            <h2 class="text__grey fs-14 fw-400 mb-1">Employer Name</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ experience.companyName }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Job Title</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ experience.jobTitle }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Duration</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{
                `${$moment(experience.startDate).format("MMM Do, YYYY")} - ${
                  !experience.endDate
                    ? "Now"
                    : $moment(experience.endDate).format("MMM Do, YYYY")
                }`
              }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Location</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ experience.country.name }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Industry</h2>
            <p class="fs-16 text__dark-grey fw-600">
              {{ experience.industry }}
            </p>
          </div>
          <div class="col-2 m-col-6">
            <h2 class="text__grey fs-14 fw-400 mb-1">Work type</h2>
            <p class="fs-16 text__dark-grey fw-600 text-capitalize">
              {{ experience.workType }}
            </p>
          </div>
        </div>

        <div>
          <h2 class="text__grey fs-14 fw-400 mb-1">Job Responsibility</h2>
          <p class="lh-32 desc">
            {{ experience.description || "No description added yet" }}
          </p>
        </div>
      </div>
    </template>

    <div class="flex flex__end">
      <Button
        @click="
          $router.push({
            name: 'update-profile',
            query: { tab: 'Skills' }
          })
        "
        class="edit__btn"
        theme="blue"
        >Edit Details</Button
      >
    </div>
  </InfoCard>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import { countries } from "@/utils/countries";

export default {
  name: "Skills",
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  components: {
    InfoCard
  },
  methods: {
    // getCountry(id) {
    //   return countries.find((country) => country.id === id).name;
    // }
  }
};
</script>

<style scoped lang="scss">
@use "../index" as *;
@use "../../../assets/scss/mixins/index" as *;

.skills__title {
  flex-basis: 100px;
}

@include query("mobile") {
  .key__skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;

    & > *:nth-child(1) {
      flex-basis: 100%;
    }
  }
}
</style>
