<template>
  <PageLayout :headerWithBG="true">
    <PageLoader v-if="loading" class="p-4 mb-20" />
    <main v-else>
      <h1 class="fs-32 m-fs-24 text__dark-grey">My Account</h1>
      <InfoCard type="plain" class="my-3" v-if="false">
        <div
          class="flex flex__item-center m-flex__column flex__col-gap-5 m-flex__row-gap-3"
        >
          <div class="flex flex__item-center m-flex__align-self-start">
            <!-- <div
              class="w-12 h-12 m-w-5 m-h-5 mr-1"
              :class="{
                user__image: profileData.profile.profilePhoto
              }"
            > -->

            <div class="w-12 h-12 m-w-5 m-h-5 mr-1">
              <img
                style="width: 100%; height: 100%"
                :src="userImage"
                alt="user-img"
              />
            </div>
            <div>
              <p class="fs-24 fw-600 text__mid-black mb-1x text-capitalize">
                {{ profileData.profile.fullName }}
              </p>
              <p class="fs-16 text__light-blue">
                {{ profileData.profile.position }}
              </p>
            </div>
          </div>
          <div
            class="flex flex__col-gap-8 flex__item-center flex__grow user__details m-flex__col-gap-2"
          >
            <!-- <div>
                            <p class="fs-14 fw-400 text__grey mb-1x">
                                Experience
                            </p>
                            <p class="fs-16 text__dark-grey">
                                {{
                                    profileData.profile.total_year_experience ||
                                    "N/A"
                                }}
                            </p>
                        </div> -->
            <div>
              <p class="fs-14 fw-400 text__grey mb-1x">Email Address</p>
              <p class="fs-16 text__dark-grey">
                {{ profileData.kc_user.email }}
              </p>
            </div>
            <div v-if="profileData.profile.mobile_number">
              <p class="fs-14 fw-400 text__grey mb-1x">Contact Information</p>
              <p class="fs-16 text__dark-grey">
                {{ profileData.profile.mobile_number }}
              </p>
            </div>
            <div>
              <p class="fs-14 fw-400 text__grey mb-1x">Address</p>
              <p class="fs-16 text__dark-grey">
                {{ profileData.profile.address || "N/A" }}
              </p>
            </div>
          </div>
        </div>
      </InfoCard>

      <Tab
        class="mb-3"
        @selected="activeSubPage = $event.component"
        :options="accountPages"
        type="rect"
        :defaultTab="defaultTab"
      />

      <template>
        <transition name="fade-from-top" mode="out-in">
          <keep-alive>
            <component
              :is="activeSubPage"
              :profileData="profileData"
            ></component>
          </keep-alive>
        </transition>
      </template>
    </main>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import InfoCard from "@/components/InfoCard";
import Tab from "@/components/Tab";
import PageLoader from "@/components/PageLoader";
import ProfileSummary from "./SubPages/ProfileSummary.vue";
import SocialNetworks from "./SubPages/SocialNetworks.vue";
import Educations from "./SubPages/Educations.vue";
import Skills from "./SubPages/Skills.vue";
import Projects from "./SubPages/Projects";
import Resume from "./SubPages/Resume.vue";
import { mapActions, mapGetters } from "vuex";
import placeholderIcon from "../../assets/icons/avatar-icon.svg";
import API from "@/const";
import seekerService from "../../services/api/seekerService";

export default {
  name: "MyAccount",
  components: {
    PageLayout,
    InfoCard,
    Tab,
    ProfileSummary,
    Educations,
    Skills,
    SocialNetworks,
    Projects,
    Resume,
    PageLoader
  },
  data() {
    return {
      accountPages: [
        { name: "Profile Summary", component: "ProfileSummary" },
        { name: "Educations", component: "Educations" },
        { name: "Experience and Skills", component: "Skills" },
        { name: "Social Networks", component: "SocialNetworks" },
        // { name: "Projects", component: "Projects" },
        { name: "Resume / CV", component: "Resume" }
      ],
      activeSubPage: "ProfileSummary",
      defaultTab: "",
      profileData: {},
      loading: false,
      profileData: {}
    };
  },
  created() {
    const tab = this.$route.query?.tab;
    if (tab) {
      const { name, component } = this.accountPages.find(
        (pg) => pg.component === tab
      );
      this.activeSubPage = component;
      this.defaultTab = name;
    }
    this.fetchAccountDetails();
  },
  computed: {
    ...mapGetters({
      loggedUser: "auth/getLoggedUser"
    }),
    userImage() {
      return placeholderIcon;
      const profilePhoto = this.profileData?.profile.profilePhoto;
      return profilePhoto ? profilePhoto : placeholderIcon;
    }
  },
  methods: {
    ...mapActions({
      $fetchAccountDetails: "apimodel/makeRequest"
    }),
    fetchAccountDetails() {
      this.loading = true;
      seekerService
        .getSeekerProfile()
        .then((res) => {
          this.profileData = res.data;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
      // const { email } = this.loggedUser;
      // try {
      //   const { data } = await this.$fetchAccountDetails({
      //     url: API.GET_PROFILE_DETAILS,
      //     queries: { email }
      //   });

      //   if (!data.error) {
      //     this.profileData = data.data;

      //     return;
      //   }

      //   this.$notify({
      //     type: "error",
      //     title: "Error",
      //     text: data.message || "Something went wrong"
      //   });
      // } catch (error) {
      //   return error;
      // } finally {
      //   this.loading = false;
      // }
    }
  }
};
</script>

<style scoped lang="scss">
@use "../../assets/scss/mixins/index" as *;

main {
  padding: 32px 80px 292px;

  @include query("mobile") {
    padding: 40px 20px;
  }
}

.mb-1x {
  margin-bottom: 3px;
}

.user {
  &__details {
    @include query("mobile") {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      align-items: flex-start;
    }
  }

  &__image {
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
